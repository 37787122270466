import HuitabAnchor from "../common/huitab-anchor";
import { StoreManager } from "../../services/store-manager";
import { StoreKeys } from "../../services/enums";

/**
 * Composant pour le widget mesures
 */
export default class WidgetMesures extends HuitabAnchor {
  /**
   * Initialise une nouvelle instance de type {@type WidgetMesures}.
   */
  constructor() {
    super();
    StoreManager.subscribe(StoreKeys.SELECTED_SIREN, this._selectedSirenChangedCallback.bind(this));
  }

  /**
   * Callback appelée au setting de la collectivité initiale et à chaque changement de collectivité.
   * Si l'habilitation "services de données" est présente pour ce siren (ou pour "all"), on affiche le bloc nominal,
   * sinon on affiche le bloc "non habilité".
   * @private
   * @param {string} siren n° siren de la collectivité sélectionnée
   */
  _selectedSirenChangedCallback(siren) {
    const nominal_div = this.querySelector(".nominal");
    const non_habilite_div = this.querySelector(".non-habilite");
    if (StoreManager.widgetVisibilityPerimetre[siren].mesures) {
      // affiche le bloc nominal et cache le bloc non habilité
      nominal_div.hidden = false;
      non_habilite_div.hidden = true;
      // active le lien (cliquable)
      this.classList.remove("disabled");
    } else {
      // cache le bloc nominal et affiche le bloc non habilité
      nominal_div.hidden = true;
      non_habilite_div.hidden = false;
      // désactive le lien (non cliquable)
      this.classList.add("disabled");
    }
  }
}
