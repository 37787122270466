import { HuitabPageManager } from "../../services/page-manager";
import { PageTypes } from "../../services/settings-loader";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import HuitabHeader from "../common/huitab-header.js";
import MicroApp from "./micro-app-base.js";

/**
 * Microapp mes données énergétiques
 */
export default class CompteursMicroApp extends MicroApp {
  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    /**
     * Afficher la fiche compteur
     * @param {string} params Les paramètres de la fiche compteur au format json
     * Exemple {"idSiren":"213101579","idPrm":"50037429625076"}
     */
    AfficherDetailCompteur: params => {
      const url = HuitabPageManager.appendParams(PageTypes.FICHE_COMPTEUR.path, JSON.parse(params));
      HuitabPageManager.openLinkInNewTab(url);
    },
    /**
     * Mise à jour du fil d'Ariane
     * @param {string} msg La clé pour identifier l'action utilisateur
     * Valeurs = [pageCockpit, mesCompteurs, mesSouscriptions, mesAutorisations, donnerAutorisation, gererAutorisation, demandeAutorisation]
     */
    MiseAJourFilAriane: msg => {
      switch (msg) {
        case "mesSouscriptions":
          HuitabHeader.setFilArianeDesktop([
            { label: "Mes services mesures" },
            { label: "Mes données énergétiques" },
            { label: "Mes services mesures" },
          ]);
          HuitabHeader.setFilArianeMobile("Mes services mesures");
          break;

        case "mesCompteurs":
          HuitabHeader.setFilArianeDesktop([
            { label: "Mes compteurs" },
            { label: "Mes données énergétiques" },
            { label: "Mes compteurs" },
          ]);
          HuitabHeader.setFilArianeMobile("Mes compteurs");
          break;

        case "pageCockpit":
          HuitabHeader.setFilArianeDesktop([
            { label: "Paramétrer mes services mesures" },
            { label: "Mes données énergétiques" },
            { label: "Paramétrer mes services mesures" },
          ]);
          HuitabHeader.setFilArianeMobile("Paramétrer mes services mesures");
          break;

        case "mesAutorisations":
          HuitabHeader.setFilArianeDesktop([
            { label: "Mes autorisations mesures" },
            { label: "Mes données énergétiques" },
            { label: "Mes autorisations mesures" },
          ]);
          HuitabHeader.setFilArianeMobile("Mes autorisations mesures");
          break;
      }
    },
    ActionMesAutorisations: msg => {
      HuitabPageManager.updateUrlWithPath("mes-donnees-energetiques");
      HuitabHeader.setFilArianeDesktop([
        { label: "Paramétrer mes services mesures" },
        { label: "Mes données énergétiques" },
        { label: "Paramétrer mes services mesures" },
      ]);
      HuitabHeader.setFilArianeMobile("Mes autorisations mesures");
    },
    MiseAjourLienHeader: msg => {
      switch (msg) {
        case "mesCompteurs":
          HuitabHeader.setLienHeader({
            activeNavLinksDesktop: ["huitab-nav-item-mes-donnees-energetiques", "huitab-nav-link-acces-mes-compteurs"],
            activeNavLinksMobile: ["huitab-nav-link-acces-mes-compteurs"],
          });
          break;

        case "pageCockpit":
          HuitabHeader.setLienHeader({
            activeNavLinksDesktop: ["huitab-nav-item-mes-donnees-energetiques", "huitab-nav-link-acces-mes-compteurs"],
            activeNavLinksMobile: ["huitab-nav-link-acces-mes-compteurs"],
          });
          break;

        case "mesAutorisations":
          HuitabHeader.setLienHeader({
            activeNavLinksDesktop: [
              "huitab-nav-item-mes-donnees-energetiques",
              "huitab-nav-link-acces-mes-autorisations",
            ],
            activeNavLinksMobile: ["huitab-nav-link-acces-mes-autorisations"],
          });
          break;
      }
    },
    retourAccueil: msg => {
      switch (msg) {
        case "ClicAutorisationVersAccueil":
          TagCommanderManager.tagClick({
            chapitre_1: "mes_donnees_energetiques",
            chapitre_2: "gerer_mes_autorisations",
            chapitre_3: "standard",
            page: "retour_vers_la_page_d_accueil",
            type: "action",
          });
          break;
      }
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type CompteursMicroApp}.
   */
  constructor() {
    super();
  }
}
