import HuitabHeader from "../common/huitab-header";
import MicroApp from "./micro-app-base";
import { HuitabPageManager, PageManager } from "../../services/page-manager";
import { StoreManager } from "../../services/store-manager";
import { StoreKeys } from "../../services/enums";
import { PageTypes } from "../../services/settings-loader";


/**
 * Microapp actualités
 */
export default class ActualitesMicroApp extends MicroApp {
  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    /**
     * Action pour ouvrir l'url dans un nouvel onglet
     * @param {string} url L'url à ouvrir dans un nouvel onglet
     */
    AfficherSite: function (url) {
      HuitabPageManager.openExternalLinkInNewTab(url);
    },
    /**
     * Réaction à la sélection d'une actualité
     * @param {string|null} titre Le titre de l'actualité sélectionnée ou null pour toutes les actualités sélectionnée
     * @constructor
     */
    ActuTitle: function (titre) {
      const filAriane = [...PageManager.current.header.filArianeDesktop];
      if (titre) {
        filAriane.push({ label: titre });
      } else {
        // TODO helios il faudrait faire passer l'id de l'article avec le titre pour mettre à jour l'url
        HuitabPageManager.updateUrlWithParam("idArticle", null);
      }
      HuitabHeader.setFilArianeDesktop(filAriane);
    },

    /**
     * Remplace l'attribut "src" de l'iframe pour permettre le routing tout en conservant le fonctionnement de Pym.JS
     * @param {string} url L'URI à insérer dans au niveau de l'url définie dans l'attribut src de l'iframe (correspond au "href" de la balise lien "a")
     * @example /collectivite/actualite/11111
     */
    UpdateIframeUrl: function (url) {
      // Récupère l'url de l'iframe
      const iframeEl = document.querySelector("#actualites-microapp iframe");
      const srcIframe = iframeEl.getAttribute("src");

      // Récupère la valeur de l'attribut "src" dans la portail et le met à jour avec l'url passée en paramètre
      const newUrl = new URL(srcIframe);
      newUrl.pathname = url;

      iframeEl.setAttribute("src", newUrl.href);
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type ActualitesMicroApp}.
   */
  constructor() {
    super();

    /**
     * On s'abonne au changement de collectivité sélectionnée
     */
    StoreManager.subscribe(StoreKeys.SELECTED_SIREN, this._displayOnSelectedSirenChanged.bind(this));
  }

  /**
   * Si une collectivité a été sélectionnée, on met à jour l'URL
   * à afficher dans la page en ajoutant en paramètre
   * les départements gérés par la collectivité, puis on rafraîchit
   * l'iframe
   *
   * @private
   * @param siren {string} Le siren sélectionné
   */
  _displayOnSelectedSirenChanged(siren) {
    const myUrl = new URL(PageTypes.ACTUALITES.microapp.url);

    // Cas accès à la page depuis le widget et arrivée sur un article
    const currentWindowParams = new URLSearchParams(window.location.search);
    if (currentWindowParams.has("idArticle")) {
      myUrl.href = myUrl.href.replace(/actualite(s)?(\/index)?/i, "actualite/" + currentWindowParams.get("idArticle"));
    }

    // Détermination des départements correspondant à la collectivité sélectionnée
    const codesDepFlat = StoreManager.getSelectedDepartements().join();

    // Ajout de la liste des départements en paramètre de l'url
    myUrl.searchParams.append("codeDep", codesDepFlat);

    // Mise à jour de l'url suite à l'ajout des paramètres
    this.url = myUrl.href;

    // Mise à jour de l'iframe suite au changement de l'url
    this.checkAndDisplayMicroapp();
  }
}
