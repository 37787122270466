import { PageManager } from "../../services/page-manager";
import { huitabSettings } from "../../services/settings-loader";

/**
 * Composant pour le bloc microapp
 */
export default class HuitabMicroapp extends HTMLElement {
  /**
   * Initialise une nouvelle instance de type {@type NeufsneufMicroapp}
   */
  constructor() {
    super();
    if (huitabSettings.feature_flipping.rationalisation_mes_demandes_colloc_enabled) {
      this._buildMicroapp();
    }
  }

  /**
   * Construction du bloc pour afficher une micro app
   * @private
   */
  _buildMicroapp() {
    const blocMicroapp = this.querySelector("#huitab-master-microapp");
    blocMicroapp.parentElement.classList.add(this.microappId);
    blocMicroapp.insertAdjacentHTML(
      "beforebegin",
      `<div id="${this.microappId}" is="${this.microappConfig.is}" class="embed-responsive"></div>`
    );
  }

  /**
   * Retourne la config de la microapp
   * @returns {MicroappConfig}
   */
  get microappConfig() {
    return PageManager.current.microapps[this.microappId];
  }

  /**
   * Retourne l'ID de la microapp
   * Si l'id de la microapp n'est pas renseigné sur le custom-element alors ce sera l'id de la première microapp
   * configurée qui sera retourné
   * @returns {String}
   */
  get microappId() {
    return this.dataset.microappId || Object.keys(PageManager.current.microapps)[0];
  }
}
