import MicroApp from "./micro-app-base.js";
import { PageManager } from "../../services/page-manager";

/**
 * Microapp migration de compte
 */
export default class CartographieReseauMicroApp extends MicroApp {

    /**
     * Initialise une nouvelle instance de type {@type MigrationCompteMicroApp}.
     */
    constructor() {
        super();

    }

    displayOnReady() {
        super.displayOnReady();
        this.style.height = PageManager.current.microapp.iframeMinHeight + "px";
    }
}
