import MicroApp from "./micro-app-base";
import { HuitabPageManager } from "../../services/page-manager";
import { StoreManager } from "../../services/store-manager";
import { StoreKeys } from "../../services/enums";
import { huitabSettings } from "../../services/settings-loader";

/**
 * Microapp widget actualités
 */
export default class ActualitesWidgetMicroApp extends MicroApp {
  /**
   * Surcharge pour désactiver la réaction à l'attribut display.
   * @return {string[]}
   * @override
   */
  static get observedAttributes() {
    return [];
  }

  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    /**
     * Afficher les actualités dans un nouvel onglet.
     *
     * @param {string|null} idArticle L'identifiant de l'article à afficher ou null pour afficher toutes les actualités.
     */
    AfficherActualites: function (idArticle) {
      let actualite_url = new URL(location.origin + "/actualites");
      if (idArticle) {
        actualite_url.searchParams.set("idArticle", idArticle);
      }
      HuitabPageManager.openLinkInNewTab(actualite_url.href);
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type ActualitesWidgetMicroApp}.
   */
  constructor() {
    super();

    /**
     * On s'abonne au changement de collectivité sélectionnée
     */
    StoreManager.subscribe(StoreKeys.SELECTED_SIREN, this._displayOnSelectedSirenChanged.bind(this));
  }

  /**
   * Si une collectivité a été sélectionnée, on met à jour l'URL
   * à afficher dans le widget en ajoutant en paramètre
   * les départements gérés par la collectivité, puis on rafraîchit
   * l'iframe
   *
   * @private
   * @param siren {string} Le siren sélectionné
   */
  _displayOnSelectedSirenChanged(siren) {
    // URL du contenu à afficher dans le widget
    const myUrl = new URL(huitabSettings.url.actualites_widget_url);

    // Détermination des départements correspondant à la collectivité sélectionnée
    const codesDepFlat = StoreManager.getSelectedDepartements().join();

    // Ajout de la liste des départements en paramètre de l'url
    myUrl.searchParams.append("codeDep", codesDepFlat);

    // Mise à jour de l'url suite à l'ajout des paramètres
    this.url = myUrl.href;
    this.urlVie = huitabSettings.url.actualites_widget_url_vie;

    // Mise à jour de l'iframe suite au changement de l'url
    this.checkAndDisplayMicroapp();
  }
}
