import { HuitabPageManager } from "../../services/page-manager";
import { TagCommanderManager } from "../../services/tag-commander-manager";

const TARGET_BLANK = "_blank";

/**
 * Composant pour définir un comportement commun sur les balises hyperlink
 */
export default class HuitabAnchor extends HTMLAnchorElement {
  /**
   * Initialise une nouvelle instance de type {@type HuitabAnchor}.
   */
  constructor() {
    super();

    // Réagir au clic sur la balise
    this.addEventListener("click", this._onAnchorClick.bind(this));
  }

  /**
   * Réaction au clic sur la balise.
   * @param event {Event} évènement de click
   * @return {boolean} Une valeur indiquant l'ouverture du lien hypertexte
   * @private
   */
  _onAnchorClick(event) {
    event.preventDefault();

    if (this.dataset.tagClick) {
      // Envoyer event tag commander de type click
      const tag = JSON.parse(this.dataset.tagClick);
      TagCommanderManager.tagClick(tag);
    }

    if (this.dataset.tagPage) {
      // Envoyer event tag commander de type page
      const tag = JSON.parse(this.dataset.tagPage);
      TagCommanderManager.tagPage(tag);
    }

    // Gestion de la redirection interne ou externe
    const href = this.getAttribute("href");
    if (!href) {
      return false;
    }

    if (this.getAttribute("target") === TARGET_BLANK) {
      // Ouverture dans un nouvel onglet si target="_blank"
      HuitabPageManager.openLinkInNewTab(href);
      return true;
    }

    if (this.dataset.externalBaseUrl) {
      // Redirection sur http://lien/externe/href
      const url = this.dataset.externalBaseUrl + "/" + href;
      HuitabPageManager.openExternalLinkInNewTab(url);
      return true;
    }

    HuitabPageManager.openLink(href);
    return true;
  }
}
