/**
 * Transforme les settings json en objet
 * @param {string} idSettings L'identifiant pour retrouver le json dans le DOM
 * @return {{}|any} Les settings
 */
function loadJsonSetings(idSettings) {
  const settingsElement = document.querySelector(
    "body > script[type='application/json'][data-8ab-settings='" + idSettings + "']"
  );

  if (settingsElement !== null) {
    return JSON.parse(settingsElement.textContent);
  }

  return {};
}

/**
 * Les settings pour le portail colloc.
 * @type {Appconfig}
 */
let huitabSettings = loadJsonSetings("8ab-settings-json");
// transformer date cgu en Date
let dateParts = huitabSettings.last_cgu_update.split("/");
huitabSettings.last_cgu_update = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
console.log("SETTINGS_CGU: " + huitabSettings.last_cgu_update);

/**
 * Les settings pour le portail colloc.
 * @type {MasterpageConfig}
 */
const huitabMasterpageSettings = loadJsonSetings("8ab-masterpagesettings-json");

export { huitabSettings, huitabMasterpageSettings as PageTypes };
