import { ComponentHelper } from "./services/component-helper";
import { HuitabProfiles } from "./services/enums";
import { MasterpageManager } from "./services/masterpage-manager";
import { PageManager } from "./services/page-manager";
import { ParcoursController } from "./services/parcours-controller";
import { huitabSettings, PageTypes } from "./services/settings-loader";
import { StoreManager } from "./services/store-manager";

configuration();
featureFlipping();
MasterpageManager.buildMasterpage();

ParcoursController.loadUserInstance().then(userInstance => {
  // Redirection
  if (ParcoursController.redirectionParcours(userInstance)) {
    return;
  }

  // Affichage du bloc main
  ComponentHelper.displayMain();

  //Configuration du store
  StoreManager.initialize(userInstance);

  // Construction du reste de la page suite au chargement de l'utilisateur
  ComponentHelper.displayMicroapps();

  ParcoursController.promptModal(userInstance.infoCompteDto);
});

/**
 * Configuration
 */
function configuration() {
  if (huitabSettings.envs.profile === HuitabProfiles.PROD) {
    // Désactiver les console log
    window.console.log = function () {};
  }
}

/**
 * Action pour désactiver des fonctionnalité du portail
 */
function featureFlipping() {
  // TODO Helios désactivation dans le menu version mobile
  // Désactivation du menu "M'informer/FAQ Documentation"
  if (!huitabSettings.feature_flipping.faq_documentation_enabled) {
    document.getElementById("flipping_faq").style.display = "none";
    document.getElementById("flipping_faq_mobile").style.display = "none";
    if (PageManager.current === PageTypes.PLAN_DU_SITE) {
      document.getElementById("flipping_faq_plan_du_site").style.display = "none";
    }
  }

  // Désactivation du menu "M'informer/Espace AODE"
  if (!huitabSettings.feature_flipping.espace_aode_enabled) {
    document.getElementById("flipping_espace_aode").style.display = "none";
    document.getElementById("flipping_espace_aode_mobile").style.display = "none";
  }

  // Désactivation du menu "M'informer/Actualités"
  if (!huitabSettings.feature_flipping.actualites_enabled) {
    document.getElementById("flipping_actualites").style.display = "none";
    document.getElementById("flipping_actualites_mobile").style.display = "none";
    if (PageManager.current === PageTypes.PLAN_DU_SITE) {
      document.getElementById("flipping_actualites_plan_du_site").style.display = "none";
    }
  }

  // Désactivation de la Widget Actualités
  if (!huitabSettings.feature_flipping.widget_actualites_enabled) {
    // On masque la page affichant le CMS
    if (document.getElementById("actualites-widget-microapp")) {
      document.getElementById("actualites-widget-microapp").style.display = "none";
    }
    // On masque la page de maintenance
    if (document.querySelector(".actualites-widget-microapp .maintenance-microapp")) {
      document.querySelector(".actualites-widget-microapp .maintenance-microapp").style.display = "none";
    }
    // On affiche la page de service en construction
    if (document.querySelector(".actualites-widget-microapp .construction-microapp")) {
      document.querySelector(".actualites-widget-microapp .construction-microapp").style.display = "";
    }
  }

  // Désactivation du menu "M'informer" si Actualités et FAQ Documentation sont désactivés
  if (
    !huitabSettings.feature_flipping.faq_documentation_enabled &&
    !huitabSettings.feature_flipping.actualites_enabled
  ) {
    document.getElementById("flipping_minformer").hidden = true;
    document.getElementById("flipping_minformer_mobile").hidden = true;
    if (PageManager.current === PageTypes.PLAN_DU_SITE) {
      document.getElementById("flipping_minformer_plan_du_site").hidden = true;
    }
  }

  // Désactivation redirection widget services mesures
  if (!huitabSettings.feature_flipping.widget_mesures_enabled) {
    if (PageManager.current === PageTypes.RACINE) {
      document.querySelector("a[is='widget-mesures']").style.pointerEvents = "none";
    }
  }
}
