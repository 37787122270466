import { huitabSettings } from "./settings-loader";
import { BaseCookie } from "./base-cookie";

/**
 * Cookie pour échanger avec les microapps
 */
class HuitabCookieMicroapp extends BaseCookie {
  /**
   * Initialise une nouvelle instance de type {@type HuitabCookieMicroapp}.
   * @param {string} name Le nom du cookie.
   * @param {boolean} encode Une valeur indiquant si la valeur du cookie est encodé
   */
  constructor(name, encode) {
    super(name, huitabSettings.envs.cookie_domain, encode);
  }

  /**
   * Set le siren sélectionné dans le cookie
   * @param {string} value Le siren sélectionné
   */
  set selectedSiren(value) {
    this.set("selected_siren", value);
  }
}

const CookieLegacy = new HuitabCookieMicroapp("oab_espace_colloc", true);
const CookieMicroapp = new HuitabCookieMicroapp("8ab_portail_colloc_microapp", false);

export { CookieLegacy, CookieMicroapp };
