import ModalBase from "./modal-base";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import { PageManager } from "../../services/page-manager";
import { PageTypes } from "../../services/settings-loader";
import { StoreManager } from "../../services/store-manager";

/**
 * La modal pour reprendre la migration
 */
export default class ModalMigrationEnCours extends ModalBase {
  /**
   * Initialise une nouvelle instance de type {@type ModalMigrationEnCours}.
   */
  constructor() {
    super();
  }

  /**
   * Action pour réagir à l'ouverture de la modal
   * @override
   */
  _onShowModal() {
    TagCommanderManager.tagPage({
      chapitre_1: "migration",
      chapitre_2: "popin",
      page: "popin_finaliser_la_migration",
    });
  }

  /**
   * Action pour réagir au clic reprendre la migration OK.
   * @override
   */
  _onAccept() {
    PageManager.redirectTo(PageTypes.MIGRATION_COMPTE_REPRISE);
  }

  /**
   * Action pour réagir au clic reprendre la migration NOK.
   * @override
   */
  _onDismiss() {
    if (StoreManager.perimetre.length > 0) {
      StoreManager.migrationAskLater = true;
      this._closeModal();
    } else {
      PageManager.logout();
    }
  }
}
