/**
 * Les types d'environnement du portail colloc
 */
const HuitabProfiles = {
  DEV: "dev",
  PROD: "prod",
};

/**
 * Les modals du portail colloc
 */
const ModalTypes = {
  CGU: "#cguModal",
  LOGOUT: "#modal-deconnexion",
  LANCER_MIGRATION: "#modal-migration-amigrer",
  REPRENDRE_MIGRATION: "#modal-migration-encours",
  EXTERNAL_LINK: "#modal-external-link",
};

/**
 * Évènements du store
 */
const StoreKeys = {
  INFO_COMPTE: "infoCompteDto",
  PERIMETRE: "perimetre",
  NB_DEMANDES_EN_COURS: "nbDemandesEnCours",
  SELECTED_SIREN: "selectedSiren",
};

/**
 * Constantes pour le portail
 */
const HuitabConstantes = {
  SIREN_ALL: "all",
};

export { HuitabProfiles, ModalTypes, StoreKeys, HuitabConstantes };
