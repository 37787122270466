import MicroApp from "./micro-app-base";
import { PageManager } from "../../services/page-manager";
import { PageTypes } from "../../services/settings-loader";

/**
 * Microapp migration de compte
 */
export default class MigrationCompteMicroApp extends MicroApp {
  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    /**
     * Action pour réagir à la fin de la migration
     */
    finParcoursMigration: () => {
      PageManager.redirectTo(PageTypes.RACINE);
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type MigrationCompteMicroApp}.
   */
  constructor() {
    super();
  }
}
