import { huitabSettings } from "../../services/settings-loader";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import { PageManager } from "../../services/page-manager";

/**
 * Composant pour définir un comportement de l'avatar
 */
export default class AvatarWidget extends HTMLDivElement {
  /** le bouton avatar
   * @type {Element}
   */
  boutonAvatar = this.children["bouton-avatar"];

  /** le volet avatar
   * @type {Element}
   */
  volet = this.children["volet-avatar"];

  /** overlay avatar
   * @type {Element}
   */
  overlay = this.children["overlay-avatar"];

  /** le bouton Fermer
   * @type {Element}
   */
  boutonFermer = this.volet.getElementsByTagName("img")["close-avatar"];

  /**
   * Gestionnaire pour l'évènement keydow.escape {@type Function}.
   */
  escapeKeyDownHandler;

  /** Est-ce que l'init du contenu de l'avatar a été fait ?
   * @Type {Boolean}
   */
  isAvatarContentInitialized = false;

  /**
   * Initialise une nouvelle instance de type {@type AvatarWidget}.
   */
  constructor() {
    super();

    // Feature flipping avatar
    if (huitabSettings.avatar.enabled) {
      this.classList.remove("d-none");
    } else {
      return;
    }

    this.boutonAvatar.addEventListener("click", this._onButtonClick.bind(this));

    this.overlay.addEventListener("click", this._onOverlayClick.bind(this));

    this.boutonFermer.addEventListener("click", this._onCloseButtonClick.bind(this));

    this.escapeKeyDownHandler = this._onKeyDown.bind(this);
  }

  /**
   * Gestionnaire de l'évènement click sur le bouton avatar.
   */
  _onButtonClick() {
    this.boutonAvatar.classList.toggle("volet-avatar-ouvert");
    this.volet.classList.toggle("d-none");
    this.overlay.classList.toggle("overlay-avatar");
    this.overlay.classList.toggle("d-none");

    if (this.boutonAvatar.classList.contains("volet-avatar-ouvert")) {
      // envoyer les tags + activer event du button "echap"
      this._tagAvatarOpened();
      this._addEventEscapeKeyDown();
      this._initAvatarContentDisplay();
    } else {
      this._tagFermetureAvatar();
      // désactiver event sur bouton "echap"
      this._removeEventEscapeKeyDown();
      this._removeAvatarContent();
    }
  }

  /**
   * Gestionnaire d'évènement click sur l'overlay.
   */
  _onOverlayClick() {
    this.boutonFermer.click();
  }

  /**
   * Gestionnaire d'évènement click sur la croix.
   */
  _onCloseButtonClick() {
    this.boutonAvatar.click();
  }

  /**
   * Gestionnaire d'évènement keydown.
   * @param {Event} e
   */
  _onKeyDown(e) {
    if (e.key == "Escape") {
      this.boutonFermer.click();
    }
  }

  /**
   * Ajoute le gestionnaire d'évènement keydown.escape au body de la page.
   */
  _addEventEscapeKeyDown() {
    document.body.addEventListener("keydown", this.escapeKeyDownHandler, true);
  }

  /**
   * Retire le gestionnaire d'évènement keydown.escape du body de la page.
   */
  _removeEventEscapeKeyDown() {
    document.body.removeEventListener("keydown", this.escapeKeyDownHandler, true);
  }

  /**
   * Envoi les tags liés à l'ouverture du volet de l'avatar.
   */
  _tagAvatarOpened() {
    TagCommanderManager.tagClick({
      chapitre_1: PageManager.current.etiquette.split(" ").join("_"),
      page: "avatar",
      type: "action",
    });
    TagCommanderManager.tagPage({
      chapitre_1: PageManager.current.etiquette.split(" ").join("_"),
      page: "avatar_ouvert",
      type: "action",
    });
  }

  /**
   * Déclenche l'initialisation de l'iframe contenu dans le volet de l'avatar.
   */
  _initAvatarContentDisplay() {
    if (!this.isAvatarContentInitialized) {
      document.querySelector("#avatar-content").setAttribute("display", "true");
      this.isAvatarContentInitialized = true;
    }
  }

  /**
   * Permet la fermeture du volet avatar
   */
  _removeAvatarContent() {
    document.querySelector("#avatar-content").setAttribute("display", "false");
    this.isAvatarContentInitialized = false;
  }

  /**
   * Envoyer le tag de fermeture de l'avatar
   */
  _tagFermetureAvatar() {
    TagCommanderManager.tagClick({
      chapitre_1: "avatar",
      page: "fermeture",
      type: "action",
    });
  }
}
