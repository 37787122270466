import { ComponentHelper } from "../../services/component-helper";
import { HuitabPageManager } from "../../services/page-manager";
import { huitabSettings } from "../../services/settings-loader";
import HuitabHeader from "../common/huitab-header";
import MicroApp from "./micro-app-base";

/**
 * Microapp aide et contact
 */
export default class AideEtContactMicroApp extends MicroApp {
  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    /**
     * Surcharge pour éviter un scintillement de la page au changement d'iframe
     */
    scrollToTop: () => {
      if (huitabSettings.feature_flipping.rationalisation_mes_demandes_colloc_enabled) {
        window.scrollTo(0, 0);
      } else {
        // pymEvent de MicroApp
        window.scrollTo(0, jQuery(this).offset().top);
      }
    },
    ActivationDemandeEnLigne: function () {
      HuitabPageManager.updateUrlFromIdTab("Demandes");
      HuitabHeader.setFilArianeDesktop([
        { label: "Aide et contact" },
        { label: "Tableau de bord", href: "/" },
        { label: "Demande en ligne" },
      ]);
      if (huitabSettings.feature_flipping.rationalisation_mes_demandes_colloc_enabled) {
        ComponentHelper.pymSendMessage("changementTabUser", "Demandes");
        this.showElement(".demandes-en-ligne-microapp");
        this.hideElement(".numeros-liens-utiles-microapp");
      }
    }.bind(this),
    ActivationNumerosUtiles: function () {
      HuitabPageManager.updateUrlFromIdTab("Numeros-liens");
      HuitabHeader.setFilArianeDesktop([
        { label: "Aide et contact" },
        { label: "Tableau de bord", href: "/" },
        { label: "Numéros et liens utiles" },
      ]);
      if (huitabSettings.feature_flipping.rationalisation_mes_demandes_colloc_enabled) {
        ComponentHelper.pymSendMessage("changementTabUser", "Numeros-liens");
        this.showElement(".numeros-liens-utiles-microapp");
        this.hideElement(".demandes-en-ligne-microapp");
      }
    }.bind(this),
  };

  /**
   * Initialise une nouvelle instance de type {@type AideEtContactMicroApp}.
   */
  constructor() {
    super();

    if (huitabSettings.feature_flipping.rationalisation_mes_demandes_colloc_enabled) {
      this.style.minHeight = "900px"; // empêche un scintillement de la page pendant le changement d'iframe

      const urlSearchParams = new URLSearchParams(window.location.search);
      const currentTab = urlSearchParams.get("idTab");
      switch (currentTab) {
        case "Demandes":
          this.showElement(".demandes-en-ligne-microapp");
          break;
        case "Numeros-liens":
          this.showElement(".numeros-liens-utiles-microapp");
          break;
        default:
          throw new Error("idTab non reconnu : ", currentTab);
      }
    }
  }

  /**
   * Masquer un élément HTML
   * @param {string} elementClass
   */
  hideElement(elementClass) {
    document.querySelector(elementClass)?.classList.add("hidden");
  }

  /**
   * Afficher un élément HTML
   * @param {string} elementClass
   */
  showElement(elementClass) {
    document.querySelector(elementClass)?.classList.remove("hidden");
  }
}
