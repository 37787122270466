import { huitabSettings } from "./settings-loader";
import { BaseCookie } from "./base-cookie";
import { HuitabProfiles } from "./enums";

/**
 * Cookie portail
 */
class HuitabCookiePortail extends BaseCookie {
  /**
   * Initialise une instance de type {@type HuitabCookiePortail}.
   */
  constructor() {
    super("8ab_portail_colloc", location.hostname, huitabSettings.envs.profile === HuitabProfiles.PROD);
  }

  /**
   * Get l'identifiant interne de l'utilisateur depuis le cookie
   * @return {string|null} Le siren sélectionné
   */
  get interneId() {
    return this.get("interne_id");
  }

  /**
   * Set l'identifiant interne de l'utilisateur dans le cookie
   * @param {string} value Le siren sélectionné
   */
  set interneId(value) {
    this.set("interne_id", value);
  }

  /**
   * Get le siren sélectionné depuis le cookie
   * @return {string|null} Le siren sélectionné
   */
  get selectedSiren() {
    return this.get("selected_siren");
  }

  /**
   * Set le siren sélectionné dans le cookie
   * @param {string} value Le siren sélectionné
   */
  set selectedSiren(value) {
    this.set("selected_siren", value);
  }

  /**
   * Get une valeur indiquant si la migration doit reprendre plus tard.
   * @return {string|null} Une valeur indiquant si la migration doit reprendre plus tard.
   */
  get migrationAskLater() {
    return this.get("migration_ask_later");
  }

  /**
   * Set une valeur indiquant si la migration doit reprendre plus tard.
   * @param {string} value La valeur indiquant si la migration doit reprendre plus tard.
   */
  set migrationAskLater(value) {
    this.set("migration_ask_later", value);
  }

  /**
   * Get une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @return {string|null} Une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  get externalLinkAskLater() {
    return this.get("external_link_ask_Later");
  }

  /**
   * Set valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @param {string} value La valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  set externalLinkAskLater(value) {
    this.set("external_link_ask_Later", value);
  }
}

const CookiePortail = new HuitabCookiePortail();
export { CookiePortail };
