import { ComponentHelper } from "./component-helper.js";
import { StoreManager } from "./store-manager.js";
import { huitabSettings, PageTypes } from "./settings-loader.js";
import { ModalTypes } from "./enums.js";

/**
 * Service pour agir sur le page
 */
class HuitabPageManager {
  /**
   * Initialise une instance de type {@type HuitabPageManager}.
   */
  constructor() {
    /**
     * La page courante
     * @private
     * @type {Masterpage}
     */
    this.currentPage = this._getCurrentPage();
  }

  /**
   * Get la page courante.
   * @returns {Masterpage} La page courante.
   */
  get current() {
    return this.currentPage;
  }

  /**
   * Get la page sur laquelle se trouve l'utilisateur.
   * Retour à la page d'accueil si la page n'est pas identifiée.
   * @returns {Masterpage} La page courante.
   */
  _getCurrentPage() {
    const path = location.pathname + location.search;
    for (const property in PageTypes) {
      const regExp = new RegExp(PageTypes[property].match, "gi");
      if (path.match(regExp)) {
        return PageTypes[property];
      }
    }

    console.error(path + " n'a pas été trouvé dans PageTypes, retour vers le Tableau de bord", PageTypes);
    this.redirectTo(PageTypes.RACINE);
    return PageTypes.RACINE;
  }

  /**
   * Action pour renvoyer l'utilisateur sur la page demandée.
   * Pas de redirection si l'utilisateur est déjà sur la page demandée.
   * @param {Masterpage} page La page.
   * @returns {boolean} Une valeur indiquant si la redirection a été enclenchée.
   */
  redirectTo(page) {
    if (this.currentPage !== page) {
      window.location.href = (page.path);
      return true;
    }
    return false;
  }

  /**
   * Action pour déconnecter la personne
   */
  logout() {
    StoreManager.delete();
    window.location.href = (huitabSettings.envs.base_url + "/logout");
  }

  /**
   * Mise à jour du param idTab dans l'URL actuellement chargée dans le navigateur
   * @param {string} idTab La valeur du paramètre
   * @static
   */
  static updateUrlFromIdTab(idTab) {
    HuitabPageManager.updateUrlWithParam("idTab", idTab);
  }

  /**
   * Mise à jour du param dans l'URL actuellement chargée dans le navigateur
   * @param {string} name Le nom du paramètre
   * @param {string|null|undefined} value La valeur du paramètre
   * @static
   */
  static updateUrlWithParam(name, value) {
    const url = new URL(window.location.href);
    if (!value) {
      url.searchParams.delete(name);
    } else {
      url.searchParams.set(name, value);
    }
    window.history.replaceState("", "", url.href);
  }

  /**
   * Mise à jour du path dans l'url.
   * @param {string} path le nouveau path. Le / dans le path (/test) n'est pas obligatoire
   */
  static updateUrlWithPath(path) {
    const url = new URL(path, window.location.origin);
    window.history.replaceState("","",url.href);
  }

  /**
   * Ouvre une URL dans l'onglet actuel du navigateur
   * @param {string} url L'URL à ouvrir
   * @static
   */
  static openLink(url) {
    window.location.href = (url);
  }

  /**
   * Ouvre une URL dans un nouvel onglet du navigateur
   * @param {string} url L'URL à ouvrir
   * @static
   */
  static openLinkInNewTab(url) {
    const cleanUrl = HuitabPageManager.cleanUrl(url);
    window.open(cleanUrl);
  }

  /**
   * Ouvre une URL dans un nouvel onglet du navigateur
   * Affiche une popin avertissant de la redirection
   * @param {string} url L'URL à ouvrir
   * @static
   */
  static openExternalLinkInNewTab(url) {
    const cleanUrl = HuitabPageManager.cleanUrl(url);
    if (StoreManager.externalLinkAskLater) {
      window.open(cleanUrl);
    } else {
      document.querySelector(ModalTypes.EXTERNAL_LINK).setAttribute("data-href", cleanUrl);
      ComponentHelper.openModal(ModalTypes.EXTERNAL_LINK);
    }
  }

  /**
   * Action pour nettoyer une URL.
   * Exemple : http://localhost//exemple/portail/colloc/// => http://localhost/exemple/portail/colloc/
   * @param {string} url L'URL à nettoyer
   * @return {string} L'URL nettoyée
   * @static
   */
  static cleanUrl(url) {
    return url.replace(/([^:]\/)\/+/g, "$1");
  }

  /**
   * Ajouter des paramètres à une URL
   * @param {string} url Une URL absolue ou relative
   * @param {object} params Les paramètres: les propriétés de l'objet sont les paramètres
   * @return {string} L'URL avec les paramètres
   */
  static appendParams(url, params) {
    const result = new URL(url, "https://urlbasebouchon");
    if (params && typeof params === "object") {
      const searchParams = new URLSearchParams(params);
      searchParams.forEach((value, key) => {
        result.searchParams.append(key, value);
      });
    }

    if (url.startsWith("http")) {
      // URL absolue
      return result.href;
    } else {
      // URL relative
      return result.pathname + result.search;
    }
  }
}

const PageManager = new HuitabPageManager();
export {HuitabPageManager, PageManager};
