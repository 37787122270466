class EnumHelper {
  /**
   * Convertit la représentation d'un enum sous forme de chaîne en objet équivalent.
   * @param name {string} la représentation sous forme de chaîne de l'enum.
   * @param type {*} le type ou l'objet de référence pour trouver l'enum.
   * @returns {null|*} si elle existe, une instance trouvée dans l'enum
   */
  static parse(name, type) {
    for (const property in type) {
      if (type[property].name.toLowerCase() === name.toLowerCase()) {
        return type[property];
      }
    }
    console.error(name + " n'a pas été trouvé dans les valeurs de :", type);
    return null;
  }
}

export { EnumHelper };
