import { LncMonCompteClientService, LNC_ESPACE } from '@odw/lnc-services/js-services/api-client/lnc-mon-compte-client.service';
import { huitabSettings } from "../services/settings-loader";
import MonCompteCollocService from "./mon-compte-colloc.service";
import MesTravauxCollocService from "./mes-travaux-colloc.service";

const monCompteClientService = new LncMonCompteClientService(huitabSettings.url.mon_compte_client_api_url, LNC_ESPACE.COLLECTIVITE);
const monCompteCollocService = new MonCompteCollocService(huitabSettings.url.mon_compte_colloc_api_url);
const mesTravauxApiService = new MesTravauxCollocService(huitabSettings.url.mes_travaux_colloc_api_url);

export { monCompteClientService, monCompteCollocService, mesTravauxApiService};
