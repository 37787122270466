import { huitabSettings } from "../../services/settings-loader";
import MicroApp from "./micro-app-base";

/**
 * Microapp widget coupures
 */
export default class WidgetAideEtContactMicroApp extends MicroApp {
  /**
   * Initialise une nouvelle instance de type {@type WidgetAideEtContactMicroApp}.
   */
  constructor() {
    super();
    this.url = huitabSettings.feature_flipping.rationalisation_mes_demandes_colloc_enabled
      ? huitabSettings.url.widget_interlocuteurs_url
      : huitabSettings.url.widget_mes_demandes_colloc_url;
    this.urlVie = huitabSettings.feature_flipping.rationalisation_mes_demandes_colloc_enabled
      ? huitabSettings.url.widget_interlocuteurs_url_vie
      : huitabSettings.url.widget_mes_demandes_colloc_url_vie;
  }
}
