import { huitabSettings } from "./settings-loader";
const latinize = require('/node_modules/latinize/latinize.js');

window.tc_vars = Object.assign({}, window.tc_vars, huitabSettings.tag_commander);

/**
 * Fonction pour envoyer un tag page via l'API tag commander
 * @param {{}} t Objet vide
 * @param {Object} e le tag
 */
let tcEventPageEvent = function (t, e) {};

/**
 * Fonction pour envoyer un tag clic via l'API tag commander
 * @param {{}} t Objet vide
 * @param {Object} e le tag
 */
let tcEventClickAtinternet = function (t, e) {};

/**
 * Une valeur indiquant si l'API tag commander est chargée
 * @type {boolean}
 */
let tcEnabled = false;

if (window.tC && window.tC.event) {
  tcEnabled = true;
  tcEventPageEvent = window.tC.event.page_event;
  tcEventClickAtinternet = window.tC.event.click_atinternet;
}

console.log("TAG_COMMANDER_ENABLED", tcEnabled);

/**
 * Service Tag Commander
 */
class HuitabTagCommanderManager {
  /**
   * Initialise une nouvelle instance de {@type HuitabTagCommanderManager}.
   */
  constructor() {}

  /**
   * Utilitaire qui va permettre de transformer le texte en une chaîne de caractères valide pour tag commander.
   * @param text {string} Le texte à transformer.
   * @return {string} Le texte au format tag commander.
   */
  static cleanText(text) {
    return latinize(text.trim())
      .replace(/[^A-Za-z0-9]/gi, "_") // On ne garde que les lettre et nombres
      .toLowerCase();
  }

  /**
   * Action pour envoyer un tag de page.
   * @param data {Object} L'objet à envoyer.
   */
  tagPage(data) {
    const tag = Object.assign({}, huitabSettings.tag_commander, data);
    console.log("TAG_COMMANDER_PAGE", tag);
    tcEventPageEvent({}, tag);
  }

  /**
   * Action pour envoyer un tag de click.
   * @param data {Object} L'objet à envoyer.
   */
  tagClick(data) {
    const tag = Object.assign({}, huitabSettings.tag_commander, data);
    console.log("TAG_COMMANDER_CLICK: ", tag);
    tcEventClickAtinternet({}, tag);
  }
}

const TagCommanderManager = new HuitabTagCommanderManager();
export { HuitabTagCommanderManager, TagCommanderManager };
