import { huitabSettings, PageTypes } from "../../services/settings-loader";
import { HuitabPageManager, PageManager } from "../../services/page-manager";
import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";
import { StoreManager } from "../../services/store-manager";
import { StoreKeys } from "../../services/enums";

/**
 * Iframe affichant le CMS de l'avatar.
 */
export default class AvatarContent extends HTMLDivElement {
  static get observedAttributes() {
    return ["display"];
  }

  //L'étiquette permet au CMS d'adapter son contenu en fonction de la page actuel.
  etiquette = null;
  urlVie = huitabSettings.avatar.cms_avatar.url_vie;
  pymEvents = {
    AfficherFaq: () => {
      HuitabPageManager.openLinkInNewTab(`${PageTypes.FAQ.path}&etiquette=${this.etiquette}`);
      
    },
    AfficherDocumentation: () => {
      HuitabPageManager.openLinkInNewTab(`${PageTypes.DOCUMENTATION.path}&etiquette=${this.etiquette}`);
    },
    AfficherActualites: () => {
      HuitabPageManager.openLinkInNewTab(PageTypes.ACTUALITES.path);
    },
    AfficherArticle: (idArticle) => {
      HuitabPageManager.openLinkInNewTab(`${PageTypes.ACTUALITES.path}?idArticle=${idArticle}`);
    },
    height: (height) => {
      this.style.height = height + "px";
    }
  };
  apiClientService = new LncBaseApiClientService("");

  constructor() {
    super();
  }

  _appendsZipcodesToIframeUrl() {
    let listDepartements = StoreManager.getSelectedDepartements();

    if (listDepartements) {
      this.url = this.url + "&zone=" + listDepartements.join(",");
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "display" && newValue === "true" && !this.pymParent) {
      if (!this.urlVie) {
        this.init();
        return;
      }
      this.isCmsAlive()
        .then(() => {
          this.init();
        })
        .catch(() => {
          this.showMaintenance();
        });
    } else if (name === "display" && newValue === "false" && this.pymParent) {
      this.url = null;
      this.pymParent.remove();
      this.pymParent = null;
    }
  }

  init() {
    if (!StoreManager.perimetre) {
      StoreManager.subscribe(StoreKeys.SELECTED_SIREN, () => {
        this.initAvatarContent();
      });
    } else {
      this.initAvatarContent();
    }
  }

  isCmsAlive() {
    return this.apiClientService.get(this.urlVie);
  }

  initEtiquette() {
    this.etiquette = PageManager.current.etiquette;
  }

  initIframeUrl() {
    this.url = huitabSettings.avatar.cms_avatar.url;
    if (!this.etiquette) {
      throw new Error("Etiquette absente");
    }
    this.url += "?etiquette=" + this.etiquette;
    this._appendsZipcodesToIframeUrl();
  }

  createIframe() {
    if (this.pymParent) {
      this.pymParent.remove();
    }
    this.pymParent = new pym.Parent(this.id, this.url);
  }

  initPymEvents() {
    const pym_events = Object.assign({}, this.pymEvents, this.childPymEvents);
    for (const key in pym_events) {
      if (pym_events.hasOwnProperty(key)) {
        this.pymParent.onMessage(key, pym_events[key]);
      }
    }
  }

  showMaintenance() {
    this.classList.add("d-none");
    const maintenanceDivElement = this.parentElement.querySelector("#encart-avatar-indiponible");
    if (maintenanceDivElement) {
      maintenanceDivElement.classList.remove("d-none");
    }
  }

  /**
   * Fonction d'initialisation qui englobe les fonctions de : récupération d’étiquette,
   * la construction de l’iframe pointante vers le CMS et le déclanchement le l'écoute
   * des évènements pymjs
   */
  initAvatarContent() {
    this.initEtiquette();
    this.initIframeUrl();
    this.createIframe();
    this.initPymEvents();
  }
}
