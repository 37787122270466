import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";
import { EnumHelper } from "../services/enum-helper";
import { StatutDemande, StatutMigration } from "./api-client-enums";

/**
 * Service pour réaliser les appels à l'api personne colloc.
 */
export default class MonCompteCollocService extends LncBaseApiClientService {

  /**
   * Action pour récupérer les informations de la personne.
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @returns {Promise<InfoCompteDto>} La promesse d'une réponse.
   */
  getInfoCompte(cnAlex) {
    return new Promise((resolve, reject) => {
      this.get("/api/private/v2/personnes/" + cnAlex + "/infos")
        .then(response => {
          // Transformer dateCGU en objet date
          const rawDateCGU = response.content.dateCGU;
          response.content.dateCGU = rawDateCGU ? new Date(rawDateCGU) : null;

          // Transformer statutMigration en objet AceStatutMigration
          const statutMigration = response.content.statutMigration;
          response.content.statutMigration = statutMigration
            ? EnumHelper.parse(statutMigration, StatutMigration)
            : null;

          resolve(response.content);
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Action pour persister la date des CGU.
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @param dateCgu {Date} La date CGU à persister.
   * @returns {Promise<bool>} La promesse d'une réponse.
   */
  postDateCgu(cnAlex, dateCgu) {
    const dateToSend =
      dateCgu.getFullYear() +
      "-" +
      ("0" + (dateCgu.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dateCgu.getDate()).slice(-2);

    const data = {
      dateCGU: dateToSend,
    };

    return new Promise((resolve, reject) => {
      this.post("/api/private/v1/personnes/" + cnAlex + "/datecgu", data)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Action pour récupérer le périmètre de la personne.
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @returns {Promise<PerimetreDto[]>} La promesse d'une réponse.
   */
  getPerimetre(cnAlex) {
    return new Promise((resolve, reject) => {
      this.get("/api/private/v1/personnes/" + cnAlex + "/perimetres")
        .then(response => {
          if (response.status === 204) {
            resolve([]);
          } else {
            resolve(response.content);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Action pour récupérer le nombre de demandes à l'état {@type StatutDemande.ENCOURS}.
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @returns {Promise<number>} La promesse d'une réponse.
   */
  getNbDemandesEnCours(cnAlex) {
    return new Promise((resolve, reject) => {
      this.get("/api/private/v1/personnes/" + cnAlex + "/demandes?statut=" + StatutDemande.ENCOURS.name)
        .then(response => {
          if (response.status === 204) {
            resolve(0);
          } else {
            resolve(response.content.length);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }
}
