import { ComponentHelper } from "./component-helper";
import { CookiePortail } from "./cookie-portail";
import { HuitabConstantes, StoreKeys } from "./enums";
import { CodeFonctionnalites } from "../api-client/api-client-enums";
import { CookieLegacy, CookieMicroapp } from "./cookie-microapp";

/**
 * Store pour le portail colloc
 * @constructor
 * @public
 */
class HuitabStoreManager {
  /**
   * Initialise une instance de type {@type HuitabStoreManager}
   */
  constructor() {
    /**
     * Les abonnées
     * @type {{}}
     * @private
     */
    this.subscribers = {};

    /**
     * Les données du store
     * @type {HuitabStoreData}
     * @private
     */
    this.store = {};
  }

  /**
   * Initialise les données du store
   * @public
   * @param {UserInstance} userInstance Les informations de l'utilisateur
   */
  initialize(userInstance) {
    // Configuration du cookie
    if (CookiePortail.interneId !== userInstance.cnAlex) {
      CookiePortail.reset();
      CookieMicroapp.reset();
      CookieLegacy.reset();
    }
    CookiePortail.interneId = userInstance.cnAlex;

    if (!CookiePortail.selectedSiren) {
      CookiePortail.selectedSiren = HuitabConstantes.SIREN_ALL;
      CookieMicroapp.selectedSiren = HuitabConstantes.SIREN_ALL;
      CookieLegacy.selectedSiren = HuitabConstantes.SIREN_ALL;
    }

    // Initialiser les donnes dans le store
    this.store = { ...userInstance };
    this.store.selectedSiren = CookiePortail.selectedSiren;
    this.store.widgetVisibilityPerimetre = this._buildWidgetVisibilityPerimetre(userInstance.perimetre);
    this.store.migrationAskLater = CookiePortail.migrationAskLater === "true";
    this.store.externalLinkAskLater = CookiePortail.externalLinkAskLater === "true";

    // Notifier les abonnés après avoir initialiser le store
    this._publish(StoreKeys.INFO_COMPTE, this.store.infoCompteDto);
    this._publish(StoreKeys.PERIMETRE, this.store.perimetre);
    this._publish(StoreKeys.NB_DEMANDES_EN_COURS, this.store.nbDemandesEnCours);
    this._publish(StoreKeys.SELECTED_SIREN, this.store.selectedSiren);
  }

  /**
   * Souscription aux events du store
   * @public
   * @param {StoreKeys} channel Evènement auquel s'abonner
   * @param {*} sub Callback à déclencher
   */
  subscribe(channel, sub) {
    this.subscribers[channel] = this.subscribers[channel] || [];
    this.subscribers[channel].push(sub);
  }

  /**
   * Action pour vérifier si l'utilisateur avait accédé au portail par le passé.
   * @return {boolean} Une valeur indiquant si l'utilisateur avait accédé au portail par le passé
   */
  wasConnected() {
    const cnAlex = CookiePortail.interneId;
    return typeof cnAlex !== "undefined" && cnAlex !== "null";
  }

  /**
   * Action pour vider le store et les informations persistées pour l'utilisateur
   */
  delete() {
    this.store = {};
    CookiePortail.delete();
    CookieMicroapp.delete();
    CookieLegacy.delete();
  }

  /**
   * Récupération des départements correspondant à la collectivité sélectionnée
   *
   * @returns {string[]} la liste des départements sans doublons
   */
  getSelectedDepartements() {
    return StoreManager.selectedSiren === HuitabConstantes.SIREN_ALL
      ? [...new Set(StoreManager.perimetre.flatMap(perimetre => perimetre.departements))]
      : StoreManager.perimetre.find(perimetre => perimetre.siren === this.selectedSiren).departements;
  }

  /**
   * Publication des events du store
   * @private
   * @param {StoreKeys} channel Evenement publié
   * @param {*} args Paramètres associés à l'évènement
   */
  _publish(channel, ...args) {
    (this.subscribers[channel] || []).forEach(sub => sub(...args));
  }

  /**
   * Construction des droits de visibilité des widgets pour l'utilisateur
   * @private
   * @param {PerimetreDto[]} perimetre Le perimetre de l'utilisateur
   * @return {WidgetVisibilityPerimetre} widgetVisibilityPerimetre Droits de visibilité des widgets pour l'utilisateur en fonction du siren
   */
  _buildWidgetVisibilityPerimetre(perimetre) {
    let widgetVisibilityPerimetre = {
      all: {
        travaux: false,
        mesures: false,
        coupures: false,
      },
    };

    perimetre.forEach(perimetreDto => {
      let widgetVisibility = {
        travaux: perimetreDto.fonctionnalites.includes(CodeFonctionnalites.INFO_TRAVAUX),
        mesures:
          perimetreDto.fonctionnalites.includes(CodeFonctionnalites.SDD_ADMIN) ||
          perimetreDto.fonctionnalites.includes(CodeFonctionnalites.SDD_STANDARD),
        coupures: perimetreDto.fonctionnalites.includes(CodeFonctionnalites.INFO_COUPURES),
      };

      widgetVisibilityPerimetre.all.travaux = widgetVisibilityPerimetre.all.travaux || widgetVisibility.travaux;
      widgetVisibilityPerimetre.all.mesures = widgetVisibilityPerimetre.all.mesures || widgetVisibility.mesures;
      widgetVisibilityPerimetre.all.coupures = widgetVisibilityPerimetre.all.coupures || widgetVisibility.coupures;

      widgetVisibilityPerimetre[perimetreDto.siren] = widgetVisibility;
    });

    return widgetVisibilityPerimetre;
  }

  /**
   * Get l'identifiant alex de la personne connectée
   * @return {string} L'identifiant alex de la personne connectée
   */
  get cnAlex() {
    return this.store.cnAlex;
  }

  /**
   * Get les informations de l'utilisateur
   * @return {InfoCompteDto} Les informations de l'utilisateur
   */
  get infoCompte() {
    return this.store.infoCompteDto;
  }

  /**
   * Set les informations de l'utilisateur
   * @param {InfoCompteDto} value Les informations de l'utilisateur
   */
  set infoCompte(value) {
    this.store.infoCompteDto = value;
    this._publish(StoreKeys.INFO_COMPTE, value);
  }

  /**
   * Get le perimetre de l'utilisateur
   * @return {PerimetreDto[]} Le perimetre de l'utilisateur
   */
  get perimetre() {
    return this.store.perimetre;
  }

  /**
   * Set le perimetre de l'utilisateur
   * @param {PerimetreDto[]} value Le perimetre de l'utilisateur
   */
  set perimetre(value) {
    this.store.perimetre = value;
    this.store.widgetVisibilityPerimetre = this._buildWidgetVisibilityPerimetre(value);
    this._publish(StoreKeys.PERIMETRE, value);
  }

  /**
   * Get le nombre de demandes en cours
   * @return {number} Le nombre de demandes en cours
   */
  get nbDemandesEnCours() {
    return this.store.nbDemandesEnCours;
  }

  /**
   * Set le nombre de demandes en cours
   * @param {number} value Le nombre de demandes en cours
   */
  set nbDemandesEnCours(value) {
    this.store.nbDemandesEnCours = value;
    this._publish(StoreKeys.NB_DEMANDES_EN_COURS, value);
  }

  /**
   * Get le siren sélectionné
   * @return {string} Le siren sélectionné
   */
  get selectedSiren() {
    return this.store.selectedSiren;
  }

  /**
   * Set le siren sélectionné
   * @param {string} value Le siren sélectionné
   */
  set selectedSiren(value) {
    if (this.store.selectedSiren === value) {
      // Rien faire si pas de changement de siren
      return;
    }

    this.store.selectedSiren = value;
    CookiePortail.selectedSiren = value;
    CookieMicroapp.selectedSiren = value;
    CookieLegacy.selectedSiren = value;
    ComponentHelper.pymSendMessage("majPerimetre", "readCookie");
    this._publish(StoreKeys.SELECTED_SIREN, value);
  }

  /**
   * Get une valeur indiquant si la migration doit reprendre plus tard.
   * @return {boolean} Une valeur indiquant si la migration doit reprendre plus tard.
   */
  get migrationAskLater() {
    return this.store.migrationAskLater;
  }

  /**
   * Set une valeur indiquant si la migration doit reprendre plus tard.
   * @param {boolean} value La valeur indiquant si la migration doit reprendre plus tard.
   */
  set migrationAskLater(value) {
    this.store.migrationAskLater = value;
    CookiePortail.migrationAskLater = value.toString();
  }

  /**
   * Get une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @return {boolean} Une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  get externalLinkAskLater() {
    return this.store.externalLinkAskLater;
  }

  /**
   * Set valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @param {boolean} value La valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  set externalLinkAskLater(value) {
    this.store.externalLinkAskLater = value;
    CookiePortail.externalLinkAskLater = value.toString();
  }

  /**
   * Get la visibilité des widgets sur le périmètre de l'utilisateur
   * @return {WidgetVisibilityPerimetre}
   */
  get widgetVisibilityPerimetre() {
    return this.store.widgetVisibilityPerimetre;
  }
}

const StoreManager = new HuitabStoreManager();
export { StoreManager };
