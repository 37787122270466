import MicroApp from './micro-app-base.js';
import HuitabHeader from '../common/huitab-header.js';
import { HuitabPageManager } from '../../services/page-manager';

/**
 * Microapp faq et documentation
 */
export default class FaqDocumentationMicroApp extends MicroApp {
  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    ActivationFaq: function () {
      HuitabPageManager.updateUrlFromIdTab("faq");
      HuitabHeader.setFilArianeDesktop([{ label: "FAQ et Documentation" }, { label: "M'informer" }, { label: "FAQ" }]);
      HuitabPageManager.openLink("/faq-documentation?idTab=faq");
    },
    ActivationDocumentation: function () {
      HuitabPageManager.updateUrlFromIdTab("documentation");
      HuitabHeader.setFilArianeDesktop([
        { label: "FAQ et Documentation" },
        { label: "M'informer" },
        { label: "Documentation" },
      ]);
      HuitabPageManager.openLink("/faq-documentation?idTab=documentation");
    },

    /**
     * Action pour ouvrir l'url dans un nouvel onglet
     * @param url {string} l'url à ouvrir dans un nouvel onglet
     */
    AfficherSite: function (url) {
      HuitabPageManager.openExternalLinkInNewTab(url);
    },

    /**
     * Action pour afficher la page numéros utiles
     */
    AfficherNumerosUtiles: function () {
      // TODO ce n'est pas un lien externe => utiliser PageManager.redirectTo
      HuitabPageManager.openExternalLinkInNewTab("/aide-et-contact?idTab=Numeros-liens");
    },

    /**
     * Action pour afficher la page demandes en ligne
     */
    AfficherDemandeEnLigne: function () {
      // TODO ce n'est pas un lien externe => utiliser PageManager.redirectTo
      HuitabPageManager.openExternalLinkInNewTab("/aide-et-contact?idTab=Demandes");
    },

    /**
     * Surcharge le scroll vers le haut de la page à la demande de la microapp pour aller au top du sujet sélectionné
     */
    scrollToTop: function (key, msg) {
      console.log("scrollToTop", key, msg);
      const positionIframeToTop = document.querySelector("#faq-documentation-microapp").getBoundingClientRect().top;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollTo = positionIframeToTop + scrollTop;
      window.scrollTo(0, parseInt(key, 10) + scrollTo);
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type FaqDocumentationMicroApp}.
   */
  constructor() {
    super();
  }
}
