import { EnumHelper } from "../services/enum-helper";
import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";
import { StatutChantier, TypeChantier } from "./api-client-enums";

/**
 * Service pour réaliser les appels à l'api travaux
 */
export default class MesTravauxCollocService extends LncBaseApiClientService {
  /**
   * Action pour récupérer le summary
   * @param cnAlex {string} ID de l'utilisateur
   * @param siren {string|null} N° de siren de la collectivité
   * @returns {Promise<ChantierSummaryDto[]>} La promesse d'une réponse
   */
  getSummary(cnAlex, siren) {
    let url = "";
    url = "/api/private/v1/personnes/" + cnAlex + "/summary";
    if (siren) {
      url += "/" + siren;
    }

    return new Promise((resolve, reject) => {
      this.get(url)
        .then(response => {
          if (response.status === 204) {
            resolve([]);
          } else {
            response.content[0].nbChantierEnCoursRaccordementClient = response.content[0].nbChantierEnCoursRaccordementClient || 0;
            response.content[0].nbChantierEnCoursModifOuvrage = response.content[0].nbChantierEnCoursModifOuvrage || 0;
            response.content[0].nbChantierEnCoursModernisationReseau = response.content[0].nbChantierEnCoursModernisationReseau || 0;
            response.content[0].derniersChantiers.forEach(chantier => {
              chantier.typeChantier = EnumHelper.parse(chantier.typeChantier, TypeChantier);
              chantier.statut = EnumHelper.parse(chantier.statut, StatutChantier);
            });
            resolve(response.content);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }
}
