import IframeBase from "./iframe-base";
import { StoreManager } from "../../services/store-manager";
import { HuitabConstantes, StoreKeys } from "../../services/enums";
import { PageTypes } from "../../services/settings-loader";

/**
 * Microapp portail développeur
 */
export default class PortailDevMicroApp extends IframeBase {
  /**
   * Surcharge pour désactiver la réaction à l'attribut display.
   * @return {string[]}
   * @override
   */
  static get observedAttributes() {
    return [];
  }

  /**
   * Initialise une nouvelle instance de type {@type MicroApp}.
   */
  constructor() {
    super();

    /**
     * Le bloc pour demander à l'utilisateur de sélectionner une collectivité
     * @type {HTMLDivElement}
     */
    this.blocErreurAucuneCollectivite = document.querySelector("#container-veuillez-selectionner-collectivite");
    this.blocErreurAucuneCollectivite.style.height = super.calcIframeHeight() + "px";

    StoreManager.subscribe(StoreKeys.SELECTED_SIREN, this._displayOnSelectedSirenChanged.bind(this));
  }

  /**
   * Surcharge de la fonction displayOnReady
   * @override
   */
  displayOnReady() {
    super.displayOnReady();

    // Afficher la microapp et masquer le bloc pour demander à l'utilisateur de sélectionner une collectivité
    this.hidden = false;
    this.blocErreurAucuneCollectivite.hidden = true;
  }

  /**
   * Met à jour l'URL et cache, affiche ou recharge l'iframe si nécessaire
   * Si une collectivité a été sélectionnée : regénère l'URL et affiche le portail dev
   * Sinon, affiche le bloc "Veuillez sélectionner une collectivité"
   * Méthode surchargée pour éviter que le portail dev s'affiche par défaut
   * @private
   * @param siren {string} Le siren sélectionné
   */
  _displayOnSelectedSirenChanged(siren) {
    // URL du portail développeur avec la collectivité
    const myUrl = new URL(PageTypes.MES_ACCES_API.microapp.url);
    myUrl.searchParams.set("collectivite", siren);
    myUrl.searchParams.set("espace", "collectivite");

    this.url = myUrl.href;

    if (StoreManager.selectedSiren === HuitabConstantes.SIREN_ALL) {
      // Masquer le bloc microapp/maintenance et afficher le bloc aucune collectivité sélectionnée
      this.parentElement.hidden = true;
      this.blocErreurAucuneCollectivite.hidden = false;
    } else {
      // Afficher le bloc microapp/maintenance et masquer le bloc aucune collectivité sélectionnée
      this.parentElement.hidden = false;
      this.blocErreurAucuneCollectivite.hidden = true;
      // Afficher la microapp
      this.checkAndDisplayMicroapp();
    }
  }
}
