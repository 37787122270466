import { StoreManager } from "../../services/store-manager";
import { HuitabConstantes, StoreKeys } from "../../services/enums";
import { mesTravauxApiService } from "../../api-client/index";

/**
 * Les valeurs possibles pour l'enum StatutWidgetTravaux
 *  EN_ATTENTE : Affichage de la page d'attente
 *  FONCTIONNEL : Affichage nominal du widget
 *  SERVICE_INDISPONIBLE : Affichage de la page maintenance
 *  AUCUNS_TRAVAUX : Affichage de la page aucuns travaux
 *  NON_HABILITE : Affichage de la page non-habilité
 */
const STATUT_WIDGET_TRAVAUX = {
  EN_ATTENTE: 0,
  FONCTIONNEL: 1,
  SERVICE_INDISPONIBLE: 2,
  AUCUNS_TRAVAUX: 3,
  NON_HABILITE: 4,
};

/**
 * Configuration du graphique du widget travaux
 */
const SUMMARY_CHART_OPTIONS = {
  cutoutPercentage: 70,
  animation: {
    animateRotate: false,
    animateScale: false,
  },
  legend: {
    display: false,
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  tooltips: {
    enabled: false,
  },
};

/**
 * Composant pour le widget travaux
 */
export default class WidgetTravaux extends HTMLDivElement {
  /**
   * Initialise une nouvelle instance de type {@type WidgetTravaux}
   */
  constructor() {
    super();
    StoreManager.subscribe(StoreKeys.SELECTED_SIREN, this.selectedSirenChangedCallback.bind(this));
  }

  /**
   * Callback appelée à chaque changement de collectivité.
   * Notamment utilisé pour la gestion de l'affichage du widget travaux lorsque le siren change
   * @param {string} siren n° siren de la collectivité sélectionnée
   */
  selectedSirenChangedCallback(siren) {
    this._showWidgetTravaux(STATUT_WIDGET_TRAVAUX.EN_ATTENTE);
    if (StoreManager.widgetVisibilityPerimetre[siren].travaux) {
      const idSiren = siren === HuitabConstantes.SIREN_ALL ? null : siren;
      mesTravauxApiService.getSummary(StoreManager.cnAlex, idSiren)
        .then(summary => {
          if (summary.length) {
            this._updateWidget(summary);
          }
          else {
            this._showWidgetTravaux(STATUT_WIDGET_TRAVAUX.AUCUNS_TRAVAUX);
          }
        })
        .catch(() => {
          this._showWidgetTravaux(STATUT_WIDGET_TRAVAUX.SERVICE_INDISPONIBLE);
        });
    } else {
      this._showWidgetTravaux(STATUT_WIDGET_TRAVAUX.NON_HABILITE);
    }
  }

  /**
   * Affiche le widget travaux selon son statut
   * @private
   * @param statut {STATUT_WIDGET_TRAVAUX} Statut du widget travaux
   */
  _showWidgetTravaux(statut) {
    this.querySelector(".wt-waiting-room").hidden = (statut !== STATUT_WIDGET_TRAVAUX.EN_ATTENTE);
    this.querySelector(".wt-content").hidden = (statut !== STATUT_WIDGET_TRAVAUX.FONCTIONNEL);
    this.querySelector("#btn-visualiser-travaux").hidden = (statut !== STATUT_WIDGET_TRAVAUX.FONCTIONNEL);
    this.querySelector("#block--maintenance-mode").hidden = (statut !== STATUT_WIDGET_TRAVAUX.SERVICE_INDISPONIBLE);
    this.querySelector(".wt-content-zero").hidden = (statut !== STATUT_WIDGET_TRAVAUX.AUCUNS_TRAVAUX);
    this.querySelector("#block--habilitation-manquante").hidden = (statut !== STATUT_WIDGET_TRAVAUX.NON_HABILITE);
  }

  /**
   * Mise à jour du widget travaux
   * @private
   * @param {ChantierSummaryDto[]} summary Données des chantiers en cours
   */
  _updateWidget(summary) {
    let chantierSummaryDto = summary[0];
    const nbChantierEnCoursRaccordementClient = chantierSummaryDto.nbChantierEnCoursRaccordementClient;
    const nbChantierEnCoursModifOuvrage = chantierSummaryDto.nbChantierEnCoursModifOuvrage;
    const nbChantierEnCoursModernisationReseau = chantierSummaryDto.nbChantierEnCoursModernisationReseau;
    const derniersChantiers = chantierSummaryDto.derniersChantiers;

    /* Travaux recap */
    this.querySelector("#nb-raccord-client").innerText = nbChantierEnCoursRaccordementClient;
    this.querySelector("#nb-modif-ouvrage").innerText = nbChantierEnCoursModifOuvrage;
    this.querySelector("#nb-modern-reseau").innerText = nbChantierEnCoursModernisationReseau;

    /* Derniers travaux mis à jour */
    const nodeDerniersChantiersTableau = this.querySelector(".widget-travaux-block .table.wt-chantiers tbody");
    nodeDerniersChantiersTableau.innerHTML = "";
    derniersChantiers.forEach(chantier => {
      nodeDerniersChantiersTableau.insertAdjacentHTML(
        "beforeend",
        `<tr>
          <td>${chantier.typeChantier.label}</td>
          <td>${chantier.adresse}</td>
          <td class="chantier-statut">${chantier.statut.label}</td>
          <td class="chantier-lien">
            <a
              is="huitab-anchor"
              aria-label="Consulter le détail des travaux ${chantier.idChantier}"
              href="/travaux?idChantier=${chantier.idChantier}"
              data-tag-click='{"chapitre_1": "accueil_espace_colloc", "chapitre_2": "travaux_en_cours", "page": "clic_fleche", "type": "navigation"}'
            >
              <i class="fas fa-chevron-right"></i>
            </a>
          </td>
        </tr>`
      );
    });

    /* Graphique */
    const graphiqueChantiersTotaux = this.querySelector("#wt-summary-chart");
    let summaryChartData = {
      datasets: [
        {
          backgroundColor: ["#93C90E", "#005EB8", "#00A3E0"],
          borderWidth: 0,
          hoverBorderWidth: 0,
        },
      ],

      // Ces étiquettes apparaissent dans la légende et dans les infobulles lors du survol des différents arcs.
      labels: ["Raccordement Client  ", "Modification d'ouvrage", "Modernisation du réseau"],
    };

    summaryChartData.datasets[0].data = [
      nbChantierEnCoursRaccordementClient,
      nbChantierEnCoursModifOuvrage,
      nbChantierEnCoursModernisationReseau,
    ];

    new Chart(graphiqueChantiersTotaux, {
      type: "doughnut",
      data: summaryChartData,
      options: SUMMARY_CHART_OPTIONS,
    });

    this.querySelector("#wt-summary-total").innerText =
      nbChantierEnCoursRaccordementClient + nbChantierEnCoursModifOuvrage + nbChantierEnCoursModernisationReseau;

    const isFonctionnel = nbChantierEnCoursModernisationReseau || nbChantierEnCoursModifOuvrage || nbChantierEnCoursRaccordementClient;
    this._showWidgetTravaux(isFonctionnel ? STATUT_WIDGET_TRAVAUX.FONCTIONNEL : STATUT_WIDGET_TRAVAUX.AUCUNS_TRAVAUX);
    }
}
