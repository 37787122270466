import MicroApp from './micro-app-base';
import { HuitabPageManager } from "../../services/page-manager";
import { huitabSettings, PageTypes } from "../../services/settings-loader";
import { StoreManager } from "../../services/store-manager";
import { StoreKeys } from "../../services/enums";

/**
 * Microapp widget coupures
 */
export default class WidgetCoupuresMicroApp extends MicroApp {
  /**
   * Surcharge pour désactiver la réaction à l'attribut display.
   * (Cette µapp réagit au changement de siren.)
   * @return {string[]}
   * @override
   */
  static get observedAttributes() {
    return [];
  }

  childPymEvents = {
    /**
     * Afficher la page coupures avec le détail de la coupure
     * @param {string} data Les paramètres de la page au format json
     * Exemple : {"idCoupure":"PESIT20220330092420.0546509167","codeInsee":"09167"}
     */
    AfficherDetailCoupure: function (data) {
      const url = HuitabPageManager.appendParams(PageTypes.INFO_COUPURES.path, JSON.parse(data));
      HuitabPageManager.openLink(url);
    },
    /**
     * Afficher la page coupures avec le détail de la crise
     * @param {string} data Les paramètres de la page au format json
     * Exemple : {"idCrise":"33","codeInsee":"59350"}
     */
    AfficherDetailCrise: function (data) {
      const url = HuitabPageManager.appendParams(PageTypes.INFO_COUPURES.path, JSON.parse(data));
      HuitabPageManager.openLink(url);
    },
    height: (height) => {
    /**
     * Message receptionné par le portail avec pymJS inclu pour augmenter la taille de l'iframe (de base dans pymJS)
     * Le composant parent de l'iframe doit être lui aussi agrandi étant donné la structure html du projet widget
     */
      document.getElementById(this.id).style.height = height + 'px';
    }
  };

  /**
   * Initialise une nouvelle instance de type {@type WidgetCoupuresMicroApp}.
   */
  constructor() {
    super();
    this.url = huitabSettings.url.widget_mes_coupures_colloc_url;
    this.urlVie = huitabSettings.url.widget_mes_coupures_colloc_url_vie;
    StoreManager.subscribe(StoreKeys.SELECTED_SIREN, this._selectedSirenChangedCallback.bind(this));
  }

  /**
   * Surcharge pour ajouter des actions spécifiques pour la µapp widget coupures
   * @override
   * @protected
   */
  displayOnReady() {
    super.displayOnReady();
    // actions spécifiques µapp coupures
    const iframe = this.querySelector("iframe");
    if (iframe) {
      iframe.style.height = "100%";
      // Ajoute des bords arrondis à l'iframe pour éviter que la bordure soit visuellement coupée dans les angles
      iframe.classList.add("rounded");
    }
  }

  /**
   * Callback appelée au setting de la collectivité initiale et à chaque changement de collectivité.
   * Si l'habilitation coupures est présente pour ce siren (ou pour "all"),on affiche le blocµapp/maintenance,
   * sinon on affiche le bloc "non habilité".
   * @private
   * @param {string} siren n° siren de la collectivité sélectionnée
   */
  _selectedSirenChangedCallback(siren) {
    const non_habilite_div = this.parentElement.parentElement.querySelector(".non-habilite");
    if (StoreManager.widgetVisibilityPerimetre[siren].coupures) {
      // si habilité coupures, init µapp si pas déjà fait
      if (!this.isMicroappAlive) {
        this.checkAndDisplayMicroapp();
      }
      // affiche le bloc µapp/maintenance et cache le bloc non habilité
      this.parentElement.hidden = false;
      non_habilite_div.hidden = true;
    } else {
      // si non habilité coupures: cache le bloc µapp/maintenance et affiche le bloc non habilité
      this.parentElement.hidden = true;
      non_habilite_div.hidden = false;
    }
  }
}
