import ModalBase from "./modal-base";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import { PageManager } from "../../services/page-manager";
import { PageTypes } from "../../services/settings-loader";

/**
 * La modal pour lancer la migration
 */
export default class ModalMigrationAMigrer extends ModalBase {
  /**
   * Initialise une nouvelle instance de type {@type ModalMigrationAMigrer}.
   */
  constructor() {
    super();
  }

  /**
   * Action pour réagir à l'ouverture de la modal
   * @override
   */
  _onShowModal() {
    TagCommanderManager.tagPage({
      chapitre_1: "migration",
      chapitre_2: "popin",
      page: "votre_portail_collectivite_se_renouvelle",
    });
  }

  /**
   * Action pour réagir au clic lancer la migration OK.
   * @override
   */
  _onAccept() {
    PageManager.redirectTo(PageTypes.MIGRATION_COMPTE);
  }

  /**
   * Action pour réagir au clic lancer la migration NOK.
   * @override
   */
  _onDismiss() {
    PageManager.logout();
  }
}
